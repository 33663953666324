import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"
import { getCurrentLangKey } from 'ptz-i18n';
import styled from 'styled-components'

import Layout from "../components/layout"
import HeaderSectionCannes from '../components/Article/HeaderSection/HeaderSectionCannes'
import SEO from "../components/seo"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`

const Articles = props => {

  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
 
 
  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  const data = get(props, 'data', [])
  const seoData = get(props, 'data.seo.nodes[0].seo', [])
  const pageData = get(props, 'data.pageData.nodes[0]', [])

  let seoObject = {
    title: seoData.title,
    description: seoData.description,
    lang: 'nl',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.pageData.nodes[0].slug,
    slugEn: props.data.translatedUrl.nodes[0].slug,
  }

  return (
    <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...seoObject} />
      <Container>
        <HeaderSectionCannes data={data} url={homeLink} pageData={pageData} location={props.location}/>
      </Container>
    </Layout>
  )

}

export default Articles

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  translatedUrl:allCraftCannesCannesEntry(filter: {siteId: {eq:1}}){
    nodes{
      uri
      slug
    }
  }
  pageData:allCraftCannesCannesEntry(filter: {siteId: {eq:2}}){
    nodes{
      title
      pageIntro
      slug
    }
  }
  seo:allCraftCannesCannesEntry(filter: {siteId: {eq:2}}){
    nodes{
      title
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
  cannesArticles: allCraftArticleArticleEntry( sort: {order: DESC, fields: postDate}, filter: {siteId: {eq:2}, categoriesServices: {elemMatch: {slug: {eq: "cannes"}}}}) {
    nodes {
      id
      title
      blogintro
      blogtext1
      slug
      blogreadtime
      dateCreated
      language
      postDate
      author {
        name
      }
      blogcoverimg {
        ... on craft_superkraft_Asset {
          id
          url
        }
      }
      categoriesServices {
        title
      }
    }
  }
  articles: allCraftArticleArticleEntry(sort: {order: DESC, fields: postDate}, filter: {siteId: {eq:2}}) {
    nodes {
      id
      title
      blogintro
      slug
      blogreadtime
      postDate
      language
      author {
        name
      }
      dateCreated
      blogcoverimg {
        ... on craft_superkraft_Asset {
          id
          url
        }
      }
      categoriesServices {
        ... on craft_serviceCategory_Category {
          title
        }
      }
    }
  }
}
`
